import { CSSProperties } from "react"
import { Row, Spin } from "antd"

const DEFAULT_STYLES: CSSProperties = {
  width: "100%",
  marginTop: "200px",
}

interface CommonSpinnerProps {
  styles?: CSSProperties
  size?: "small" | "large"
}

export const CommonSpinner = ({
  styles,
  size,
}: CommonSpinnerProps): JSX.Element => {
  return (
    <Row justify="center" style={styles ? styles : DEFAULT_STYLES}>
      <Spin className={size ? size : "default"} />
    </Row>
  )
}
