import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["Categories"],
})

export const categoriesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<any, any>({
      query: (userId) => ({
        url: `${createApiLink(`/Users/${userId}/Categories/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Categories" }],
    }),
    updateCategoryStatus: builder.mutation<any, any>({
      query: ({ userId, body }) => {
        return {
          url: `${createApiLink(`/Users/${userId}/CategoriesRoles/`)}`,
          method: "PUT",
          body,
          validateStatus: (response, result) =>
            validateError(response, result, true),
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Categories" }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetCategoriesQuery, useUpdateCategoryStatusMutation } =
  categoriesApi
