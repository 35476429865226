import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: [
    "ClientsGroups",
    "Clients",
    "Trackers",
    "Currencies",
    "ClientsForSpecTables",
  ],
})

export const integrationsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    // clients groups
    getClientsGroups: builder.query<any, any>({
      query: ({ userId, page = 1 }) => ({
        url: `${createApiLink(
          `/users/${userId}/ClientsGroups/`,
          // `filter[page]=${page}`,
        )}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      // Only have one cache entry because the arg always maps to one string
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName
      // },
      // Always merge incoming data to the cache entry
      // merge: (currentCache, newItems) => {
      //   currentCache.push(...newItems)
      // },
      // Refetch when the page arg changes
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg
      // },
      providesTags: (result, error, arg) => {
        return [{ type: "ClientsGroups" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    addClientGroup: builder.mutation<any, any>({
      query: ({ userId, body }) => ({
        url: `${createApiLink(`/users/${userId}/ClientsGroups/`)}`,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "ClientsGroups" }],
    }),
    changeClientGroup: builder.mutation<any, any>({
      query: ({ userId, body }) => ({
        url: `${createApiLink(`/users/${userId}/ClientsGroups/`)}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "ClientsGroups" }],
    }),
    deleteClientGroup: builder.mutation<any, any>({
      query: ({ userId, body }) => ({
        url: `${createApiLink(`/users/${userId}/ClientsGroups/`)}`,
        method: "DELETE",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "ClientsGroups" }],
    }),
    changeClientsGroupsRules: builder.mutation<any, any>({
      query: ({ userId, groupId, body }) => ({
        url: `${createApiLink(
          `/users/${userId}/ClientsGroups/${groupId}/Rules/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "ClientsGroups" }],
    }),
    // clients
    getClients: builder.query<any, any>({
      query: ({ userId, groupId }) => ({
        url: `${createApiLink(
          `/users/${userId}/ClientsGroups/${groupId}/Clients/`,
        )}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "Clients" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    getClientById: builder.query<any, any>({
      query: ({ userId, groupId, clientId }) => ({
        url: `${createApiLink(
          `/users/${userId}/ClientsGroups/${groupId}/Clients/${clientId}/`,
        )}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "Clients" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    addClient: builder.mutation<any, any>({
      query: ({ userId, groupId, body }) => ({
        url: `${createApiLink(
          `/users/${userId}/ClientsGroups/${groupId}/Clients/`,
        )}`,
        method: "POST",
        body,
        validateStatus: (response, result) => validateError(response, result),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Clients" }],
    }),
    changeClient: builder.mutation<any, any>({
      query: ({ userId, groupId, body }) => ({
        url: `${createApiLink(
          `/users/${userId}/ClientsGroups/${groupId}/Clients/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Clients" }],
    }),
    deleteClient: builder.mutation<any, any>({
      query: ({ userId, groupId, body }) => ({
        url: `${createApiLink(
          `/users/${userId}/ClientsGroups/${groupId}/Clients/`,
        )}`,
        method: "DELETE",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Clients" }],
    }),

    // Trackers
    getTrackers: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Trackers/`)}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "Trackers" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    getStaticTrackers: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/StaticTrackers/`)}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "Trackers" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    getClfgUsers: builder.query<any, any>({
      query: ({ userId }) => ({
        url: `${createApiLink(`/users/${userId}/ClfgUsers/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "ClientsGroups" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    getCurrencies: builder.query<any, any>({
      query: () => ({
        url: `${createApiLink(`/Currencies/`)}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "Currencies" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    getClientsForSpecTables: builder.query<any, any>({
      query: ({ userId }) => ({
        url: `${createApiLink(`/users/${userId}/Clients/`)}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "ClientsForSpecTables" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetClientsGroupsQuery,
  useAddClientGroupMutation,
  useChangeClientGroupMutation,
  useDeleteClientGroupMutation,
  useGetClientsQuery,
  useGetClientByIdQuery,
  useAddClientMutation,
  useChangeClientMutation,
  useDeleteClientMutation,
  // trackers
  useGetTrackersQuery,
  useGetStaticTrackersQuery,
  // clfg users
  useGetClfgUsersQuery,
  // currencies
  useGetCurrenciesQuery,
  useChangeClientsGroupsRulesMutation,
  useGetClientsForSpecTablesQuery,
} = integrationsApi
