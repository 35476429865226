import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["trackers"],
})

export const trackersApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    // Trackers
    getTrackersByClientId: builder.query<any, any>({
      query: ({ clientId }) => ({
        url: `${createApiLink(`/Clients/${clientId}/trackers/`)}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "trackers" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    getRegInfoByTrackerId: builder.query<any, any>({
      query: ({ userId, clientId, trackerId, paramsString }) => ({
        url: `${createApiLink(
          `/Users/${userId}/clients/${clientId}/trackers/${trackerId}/RegInfo/`,
          paramsString,
        )}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "trackers" }]
      },
      transformResponse: (response: any) => {
        // let data = []
        // if (response.accounts) {
        //   data = {
        //     trackerSubType: "accounts",
        //     data: response.accounts,
        //   }
        // } else if (response.fields) {
        //   if (response.convs) {
        //     data = {
        //       trackerSubType: "fields",
        //       data: {
        //         fields: response.fields,
        //         selects: response.convs,
        //       },
        //     }
        //   } else {
        //     data = {
        //       trackerSubType: "fields",
        //       data: {
        //         fields: response.fields,
        //       },
        //     }
        //   }
        // } else if (Array.isArray(response)) {
        //   data = {
        //     trackerSubType: "activation",
        //     data: response,
        //   }
        // } else if (response.agency_accounts) {
        //   data = {
        //     trackerSubType: "agencyAccounts",
        //     data: response.agency_accounts,
        //   }
        // }
        // return data
        return response
      },
    }),
    // only for agency accounts
    // https://lively-station-979547.postman.co/workspace/my.scroogefrog.com~4400a461-8dbe-44dd-8d0d-7f8d6fad0e27/request/27803675-204184c2-af1d-409e-9039-9ef0167f4433?ctx=documentation
    getAccountsByAgencyAccountId: builder.query<any, any>({
      query: ({ userId, clientId, trackerId, agencyAccountId }) => ({
        url: `${createApiLink(
          `/Users/${userId}/clients/${clientId}/trackers/${trackerId}/RegInfo/`,
          `agency_acc_id=${agencyAccountId}`,
        )}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "trackers" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    addTracker: builder.mutation<any, any>({
      query: ({ userId, clientId, trackerId, body }) => ({
        url: `${createApiLink(
          `/users/${userId}/clients/${clientId}/trackers/${trackerId}/`,
        )}`,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "trackers" }],
    }),
    deleteTracker: builder.mutation<any, any>({
      query: ({ clientId, trackerId }) => ({
        url: `${createApiLink(`/clients/${clientId}/trackers/${trackerId}/`)}`,
        method: "DELETE",
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "trackers" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  // trackers
  useGetTrackersByClientIdQuery,
  useGetRegInfoByTrackerIdQuery,
  useAddTrackerMutation,
  useDeleteTrackerMutation,
  useGetAccountsByAgencyAccountIdQuery,
  // clfg users
} = trackersApi
