import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export const getSectionType = (sectionType: string) =>
  sectionType === "passwords" ? "sections" : "networksSections"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["sections", "networksSections"],
})

export const sectionsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    // general sections
    getSections: builder.query<any, any>({
      query: ({ sectionType, userId }) => ({
        url: `${createApiLink(
          `/Users/${userId}/${getSectionType(sectionType)}/`,
        )}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => {
        return [
          {
            type: getSectionType(
              arg.sectionType === "passwords" ? "sections" : "networksSections",
            ),
          },
        ]
      },
      transformResponse: (response: any, meta, arg) => {
        const data = response.map((item: any) => {
          return {
            ...item,
            sectionType:
              arg.sectionType === "passwords" ? "sections" : "networksSections",
          }
        })
        return data
      },
    }),
    addSection: builder.mutation<any, any>({
      query: ({ sectionType, userId, body }) => ({
        url: `${createApiLink(
          `/Users/${userId}/${getSectionType(sectionType)}/`,
        )}`,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          {
            type: getSectionType(
              arg.sectionType === "passwords" ? "sections" : "networksSections",
            ),
          },
        ]
      },
    }),
    changeSection: builder.mutation<any, any>({
      query: ({ sectionType, userId, body }) => ({
        url: `${createApiLink(
          `/Users/${userId}/${getSectionType(sectionType)}/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          {
            type: getSectionType(
              arg.sectionType === "passwords" ? "sections" : "networksSections",
            ),
          },
        ]
      },
    }),
    deleteSection: builder.mutation<any, any>({
      query: ({ sectionType, userId, body }) => ({
        url: `${createApiLink(
          `/Users/${userId}/${getSectionType(sectionType)}/`,
        )}`,
        method: "DELETE",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          {
            type: getSectionType(
              arg.sectionType === "passwords" ? "sections" : "networksSections",
            ),
          },
        ]
      },
    }),

    // sections rules
    getSectionsRules: builder.query<any, any>({
      query: ({ userId, sectionId }) => {
        return {
          url: `${createApiLink(`/${userId}/Sections/${sectionId}/Rules/`)}`,
          method: "GET",
        }
      },
      providesTags: (result, error, arg) => {
        return [{ type: getSectionType(arg.sectionType) }]
      },
    }),
    changeSectionsRules: builder.mutation<any, any>({
      query: ({ userId, sectionId, sectionType, body }) => ({
        url: `${createApiLink(
          `/${userId}/${sectionType}/${sectionId}/Rules/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [
          {
            type: getSectionType(
              arg.sectionType === "passwords" ? "sections" : "networksSections",
            ),
          },
        ]
      },
    }),
    // TODO refactor
    getAllSections: builder.query<any, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const userId = localStorage.getItem("userId")
        const passwordsSections = (await fetchWithBQ(
          `${createApiLink(`/Users/${userId}/Sections/`)}`,
        )) as any
        const networksSections = (await fetchWithBQ(
          `${createApiLink(`/Users/${userId}/NetworksSections/`)}`,
        )) as any
        // return passwordsSections.data.concat(networksSections.data)
        // return passwordsSections
        const result = passwordsSections.data.concat(networksSections.data)
        return {
          data: result,
        }
      },
      providesTags: (result, error, arg) => {
        return [
          { type: getSectionType("Sections") },
          { type: getSectionType("NetworksSections") },
        ]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  // sections
  useGetSectionsQuery,
  useAddSectionMutation,
  useChangeSectionMutation,
  useDeleteSectionMutation,
  // sections rules
  useGetSectionsRulesQuery,
  useChangeSectionsRulesMutation,
  //
  useGetAllSectionsQuery,
  // useAddSectionsRulesMutation,
} = sectionsApi
