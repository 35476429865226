import {
  Action,
  configureStore,
  ThunkAction,
  createListenerMiddleware,
  isRejectedWithValue,
} from "@reduxjs/toolkit"
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { usersApi } from "@/redux/slices/users"
import { sectionsApi } from "@/redux/slices/sections"
import { tablesApi } from "@/redux/slices/tables"
import { categoriesApi } from "@/redux/slices/categories"
import { authApi } from "@/redux/slices/auth"
import { integrationsApi } from "@/redux/slices/integrations"
import { staticTrackersApi } from "@/redux/slices/staticTrackers"
import { trackersApi } from "@/redux/slices/trackers"
import toast from "react-hot-toast"
import * as Sentry from "@sentry/react"

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn(
        "Async error! We got a rejected action!",
        action.error.data.message,
      )
      toast.error("This didn't work.")
    }

    return next(action)
  }

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware()

// listenerMiddleware.startListening({
//   matcher: authApi.endpoints.login.matchFulfilled,
//   effect: async (action, listenerApi) => {
//     // listenerApi.dispatch(usersApi.endpoints.getUsers.initiate())
//   },
// })

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [sectionsApi.reducerPath]: sectionsApi.reducer,
    [tablesApi.reducerPath]: tablesApi.reducer,
    [integrationsApi.reducerPath]: integrationsApi.reducer,
    [staticTrackersApi.reducerPath]: staticTrackersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      // .prepend(rtkQueryErrorLogger)
      // .prepend(listenerMiddleware.middleware)
      .concat(authApi.middleware)
      .concat(usersApi.middleware)
      .concat(sectionsApi.middleware)
      .concat(tablesApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(integrationsApi.middleware)
      .concat(staticTrackersApi.middleware)
      .concat(trackersApi.middleware),
  // .concat(sentryReduxEnhancer),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
