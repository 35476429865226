import { emptySplitApi } from "@/redux/emptySplitApi"
import { validateError } from "@/utils"

type AuthState = {
  //   user: User | null
  token: string | null
}

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["Auth"],
})

export const authApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (body) => ({
        url: "/Auth/",
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      // https:redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
      transformResponse: (response: any, meta, arg) => {
        if (response?.state === "ok") {
          localStorage.setItem("token", response?.api_token)
          localStorage.setItem("userId", response?.user_id)
          localStorage.setItem("userName", arg.login)
          if (response?.is_admin) {
            localStorage.setItem("isAdmin", response?.is_admin)
          }
          return response
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Auth" }],
    }),
  }),
  overrideExisting: false,
})

export const { useLoginMutation } = authApi
