import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["StaticTrackers"],
})

export const staticTrackersApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    // Trackers
    getStaticTrackersByClientId: builder.query<any, any>({
      query: ({ clientId }) => ({
        url: `${createApiLink(`/Clients/${clientId}/StaticTrackers/`)}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "StaticTrackers" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    getRegInfoByStaticTrackerId: builder.query<any, any>({
      query: ({ userId, trackerId }) => {
        return {
          url: `${createApiLink(
            `/users/${userId}/StaticTrackers/${trackerId}/RegInfo/`,
          )}`,
          method: "GET",
          validateStatus: (response, result) => validateError(response, result),
        }
      },
      providesTags: (result, error, arg) => {
        return [{ type: "StaticTrackers" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    addStaticTracker: builder.mutation<any, any>({
      query: ({ clientId, trackerId, body }) => ({
        url: `${createApiLink(
          `/clients/${clientId}/StaticTrackers/${trackerId}/`,
        )}`,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "StaticTrackers" }],
    }),
    deleteStaticTracker: builder.mutation<any, any>({
      query: ({ clientId, trackerId }) => ({
        url: `${createApiLink(
          `/clients/${clientId}/StaticTrackers/${trackerId}/`,
        )}`,
        method: "DELETE",
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "StaticTrackers" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  // trackers
  useGetStaticTrackersByClientIdQuery,
  useGetRegInfoByStaticTrackerIdQuery,
  useAddStaticTrackerMutation,
  useDeleteStaticTrackerMutation,
  // useGetStaticTrackersQuery,
  // clfg users
} = staticTrackersApi
