import toast from "react-hot-toast"
import { API_URL } from "@/redux/emptySplitApi"

export const validateError = (
  response: any,
  result: any,
  isNotif: boolean = false,
) => {
  // console.log(response, result)
  const isValid = response.status === 200 && result.state !== "err"
  if (!isValid) {
    toast.error(result.data)
  } else {
    if (isNotif) {
      toast.success("Success")
    }
  }
  return isValid
}

export const createApiLink = (
  path: string,
  param?: string,
  // isDev?: boolean,
) => {
  if (param) {
    return `${API_URL}${path}?api_token=${localStorage.getItem(
      "token",
    )}&${param}`
  } else return `${API_URL}${path}?api_token=${localStorage.getItem("token")}`
}

export function tryParseJSON(jsonString: string) {
  try {
    return JSON.parse(jsonString)
  } catch {
    return null
  }
}
