import { ReactNode, Suspense } from "react"
import { CommonSpinner } from "@/components/Common/Spinner"

interface CommonSuspenseProps {
  children: ReactNode
}

export const CommonSuspense = ({
  children,
}: CommonSuspenseProps): JSX.Element => {
  return <Suspense fallback={<CommonSpinner />}>{children}</Suspense>
}
