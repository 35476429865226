export enum ModalType {
  Create = "Create",
  Edit = "Edit",
  Update = "Update",
}

export enum FeatureType {
  Networks = "networks",
  Passwords = "passwords",
}
