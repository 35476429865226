import { useEffect, lazy } from "react"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { CommonSuspense } from "@/components/Common/Suspense"
import { AuthLayout } from "@/components/Layouts/AuthLayout"
import { FeatureType } from "@/enums"

const Login = lazy(() => import("@/components/Login"))
const Categories = lazy(() => import("@/components/Categories"))
const Roles = lazy(() => import("@/components/Roles"))
const NetworksAndPasswords = lazy(
  () => import("@/components/NetworksAndPasswords"),
)
const Integrations = lazy(() => import("@/components/Integrations"))
const Clients = lazy(() => import("@/components/Clients"))
const Client = lazy(() => import("@/components/Client"))

function App() {
  const navigate = useNavigate()
  const location = useLocation()

  const userToken: string | null = localStorage.getItem("token")
  const isAdmin: boolean = localStorage.getItem("isAdmin") === "true"

  const afterLoginPath: string = isAdmin ? "/" : "/passwords"

  useEffect(() => {
    if (!userToken) {
      navigate("/login")
    } else if (location.pathname === "/") {
      navigate(afterLoginPath)
    } else return
  }, [])
  return (
    <>
      <Toaster position="top-right" />
      <Routes>
        <Route
          path="/login"
          element={
            <CommonSuspense>
              <Login />
            </CommonSuspense>
          }
        />

        <Route element={<AuthLayout />}>
          <Route
            path="/"
            element={
              <CommonSuspense>
                <Roles />
              </CommonSuspense>
            }
          />
          <Route
            path="/users/categories"
            element={
              <CommonSuspense>
                <Categories />
              </CommonSuspense>
            }
          />
          <Route
            path="/passwords"
            element={
              <CommonSuspense>
                <NetworksAndPasswords feature={FeatureType.Passwords} />
              </CommonSuspense>
            }
          />
          <Route
            path="/networks"
            element={
              <CommonSuspense>
                <NetworksAndPasswords feature={FeatureType.Networks} />
              </CommonSuspense>
            }
          />
          <Route
            path="/integrations"
            element={
              <CommonSuspense>
                <Integrations />
              </CommonSuspense>
            }
          />
          <Route
            path="/integrations/:id"
            element={
              <CommonSuspense>
                <Clients />
              </CommonSuspense>
            }
          />
          <Route
            path="/integrations/:id/clients/:clientId"
            element={
              <CommonSuspense>
                <Client />
              </CommonSuspense>
            }
          />
          <Route
            path="*"
            element={
              <CommonSuspense>
                <div>Page Not found</div>
              </CommonSuspense>
            }
          />
        </Route>
      </Routes>
    </>
  )
}

export default App
