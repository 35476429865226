import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const API_URL = "https://my.scroogefrog.com/api/v1"
export const API_URL_DEV = "https://my.scroogefrog.com/api/dev"

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
})
