import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export interface State {
  users: []
  isLoading: boolean
  error?: string
}

const initialState: State = {
  users: [],
  isLoading: false,
  error: undefined,
}

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["Users"],
})

export const usersApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<any, void>({
      query: () => ({
        url: `${createApiLink("/users/")}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: "Users" }]
      },
      transformResponse: (response: any) => {
        return response
      },
    }),
    addNewUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `${createApiLink("/users/")}`,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Users" }],
    }),
    updateUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `${createApiLink("/users/")}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Users" }],
    }),
    deleteUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `${createApiLink("/users/")}`,
        method: "DELETE",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Users" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUsersQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi
