import { createApiLink, validateError } from "@/utils"
import { emptySplitApi } from "@/redux/emptySplitApi"

export interface State {
  tables: []
  isLoading: boolean
  error?: string
}

const initialState: State = {
  tables: [],
  isLoading: false,
  error: undefined,
}

interface OriginalData {
  info: {
    id: string
    name: string
    cols: string
    spec_cols?: string[]
  }
  data: {
    headers: string[]
    rows: {
      [key: string]: {
        rules: string[]
        cols: string[]
      }
    }
  }
}

type Column = {
  title: string
  dataIndex: string
  editable: string
}

type Row = {
  id: string
  key: number
  // [value: string]: string | string[]
  rules: string[]
}

interface TransformedData {
  tableId: string
  tableName: string
  tableType: string
  // number of columns
  cols: string
  columns: Column[]
  data: Row[]
  specCols?: string[]
}

const transformData = (
  originalData: OriginalData,
  arg: any,
): TransformedData => {
  const { info, data } = originalData
  const tableType = arg.tableType
  const { id, name, cols, spec_cols } = info
  const { headers, rows } = data

  const columns = headers.map((header) => ({
    title: header,
    dataIndex: header,
    editable: "true",
  }))

  const transformedData = Object.entries(rows).map(
    ([key, row], idx: number) => {
      const rowData = {
        key: idx + 1,
        id: key,
        ...Object.fromEntries(
          headers.map((header, index) => [header, row.cols[index]]),
        ),
        rules: row.rules,
      }
      return rowData
    },
  )

  return {
    tableId: id,
    tableName: name,
    tableType,
    cols,
    columns: columns,
    specCols: spec_cols?.length ? spec_cols : [],
    data: transformedData,
  }
}

export const getTablesType = (tableType: string) =>
  tableType === "passwords" ? "tables" : "networksTables"

export const getRowsType = (tableType: string) =>
  tableType === "passwords" ? "rows" : "networksRows"

export const apiWithTag = emptySplitApi.enhanceEndpoints({
  addTagTypes: ["tables", "networksTables"],
})

export const tablesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTables: builder.query<any, any>({
      query: ({ tableType, userId, sectionId }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/`,
          `section_id=${sectionId}`,
        )}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    getTableById: builder.query<any, any>({
      query: ({ tableType, userId, tableId }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/`,
        )}`,
        method: "GET",
        // validateStatus: (response, result) =>
        //   validateError(response, result, true),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
      transformResponse: (response: OriginalData, meta, arg) => {
        const data = transformData(response, arg)
        return data
      },
    }),
    addTable: builder.mutation<any, any>({
      query: ({ tableType, userId, body }) => ({
        url: `${createApiLink(`/${userId}/${getTablesType(tableType)}/`)}`,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    changeTable: builder.mutation<any, any>({
      query: ({ tableType, userId, tableId, body }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    deleteTable: builder.mutation<any, any>({
      query: ({ tableType, userId, tableId, body }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/`,
        )}`,
        method: "DELETE",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    addNewRow: builder.mutation<any, any>({
      query: ({ userId, tableType, tableId, body }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/${getRowsType(
            tableType,
          )}/`,
        )}`,
        method: "POST",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    updateRow: builder.mutation<any, any>({
      query: ({ tableType, userId, tableId, body }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/${getRowsType(
            tableType,
          )}/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    deleteRow: builder.mutation<any, any>({
      query: ({ tableType, userId, tableId, body }) => {
        return {
          url: `${createApiLink(
            `/${userId}/${getTablesType(tableType)}/${tableId}/${getRowsType(
              tableType,
            )}/`,
          )}`,
          method: "DELETE",
          body,
          validateStatus: (response, result) =>
            validateError(response, result, true),
        }
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    updateTableSpecCols: builder.mutation<any, any>({
      query: ({ tableType, userId, tableId, tableName, specCols }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/`,
        )}`,
        method: "PUT",
        body: {
          table_name: tableName,
          spec_cols: specCols,
        },
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    getRowRules: builder.query<any, any>({
      query: ({ userId, tableId, tableType, rowId }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/${getRowsType(
            tableType,
          )}/${rowId}/Rules/`,
        )}`,
        method: "GET",
        validateStatus: (response, result) => validateError(response, result),
      }),
      providesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
    updateRowRules: builder.mutation<any, any>({
      query: ({ userId, tableId, tableType, rowId, body }) => ({
        // /Tables/{table_id}/Rows/{row_id}/Rules/
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/${getRowsType(
            tableType,
          )}/${rowId}/Rules/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),

    // TABLES RULES
    changeTablesRules: builder.mutation<any, any>({
      query: ({ userId, tableId, tableType, body }) => ({
        url: `${createApiLink(
          `/${userId}/${getTablesType(tableType)}/${tableId}/Rules/`,
        )}`,
        method: "PUT",
        body,
        validateStatus: (response, result) =>
          validateError(response, result, true),
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: getTablesType(arg.tableType) }]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTablesQuery,
  useGetTableByIdQuery,
  useAddTableMutation,
  useChangeTableMutation,
  useDeleteTableMutation,
  useAddNewRowMutation,
  useDeleteRowMutation,
  useUpdateRowMutation,
  useUpdateTableSpecColsMutation,
  // rules
  useGetRowRulesQuery,
  useUpdateRowRulesMutation,
  // tables rules
  useChangeTablesRulesMutation,
} = tablesApi
