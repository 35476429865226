import { useState } from "react"
import { ReactComponent as LogoIcon } from "@/logo.svg"
import {
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  DesktopOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"
import type { MenuProps } from "antd"
import {
  Layout,
  Menu,
  theme,
  Avatar,
  Switch,
  ConfigProvider,
  Typography,
  Tooltip,
} from "antd"
import { useGetCategoriesQuery } from "@/redux/slices/categories"
import { useNavigate, NavLink, Outlet, useLocation } from "react-router-dom"
import { CommonSpinner } from "@/components/Common/Spinner"

const { Content, Sider } = Layout

export const AuthLayout = (): JSX.Element => {
  const [collapsed, setCollapsed] = useState(
    process.env.NODE_ENV === "development" ? false : false,
  )
  const [themeState, setTheme] = useState(
    localStorage.getItem("theme") || "dark",
  )

  const isAdmin = localStorage.getItem("isAdmin") === "true"
  const userId = localStorage.getItem("userId")
  const userToken = localStorage.getItem("token")

  const navigate = useNavigate()
  const location = useLocation()

  const { data: categories, isLoading } = useGetCategoriesQuery(
    isAdmin ? 1 : userId,
  )

  const getCurrent = (): string => {
    const path = location.pathname
    if (path === "/" || path.includes("/users")) {
      return "1"
    } else if (path === "/passwords") {
      return "4"
    } else if (path === "/networks") {
      return "6"
    } else if (path.includes("/integrations")) {
      return "7"
    } else {
      return "10"
    }
  }

  const [current, setCurrent] = useState<string>(getCurrent())

  const handleMenuClick: MenuProps["onClick"] = (e): void => {
    setCurrent(e.key)
    if (e.key === "10") {
      localStorage.clear()
      navigate("/login")
    }
  }

  const onThemeChange = (): void => {
    const newTheme = themeState === "dark" ? "default" : "dark"
    localStorage.setItem("theme", newTheme)
    setTheme(newTheme)
  }

  if (!userToken) {
    navigate("/login")
  }

  if (isLoading) {
    return <CommonSpinner />
  }

  return (
    <ConfigProvider
      theme={{
        algorithm:
          themeState === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: themeState === "dark" ? "#00b96b" : "#1f7a1f",
        },
      }}
    >
      <Layout style={{ minHeight: "100vh" }} hasSider>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          theme="light"
        >
          <div
            className="demo-logo-vertical"
            style={{
              display: "flex",
              margin: "20px auto",
              width: "50px",
              height: "50px",
            }}
          >
            <NavLink to="/">
              <LogoIcon
                style={{
                  display: "flex",
                  width: "50px",
                  height: "50px",
                }}
              />
            </NavLink>
          </div>
          <Avatar
            size={64}
            className="avatar"
            style={{ display: "flex", margin: "20px auto" }}
          >
            {isAdmin ? "Admin" : "Not Admin"}
          </Avatar>
          {!collapsed && (
            <Typography.Title
              style={{ textAlign: "center", color: "green" }}
              level={5}
            >
              {localStorage.getItem("userName")}
            </Typography.Title>
          )}

          <Menu
            style={{ borderInlineEnd: "none" }}
            defaultSelectedKeys={isAdmin ? ["admin"] : ["passwords"]}
            selectedKeys={[current]}
            mode="inline"
            items={categories
              .filter((item: any) => item.category_enable)
              ?.map((item: any) => {
                return getMenuItem(item)
              })
              .concat({
                key: "10",
                label: <Logout />,
                icon: <LogoutOutlined />,
              })}
            onClick={handleMenuClick}
          />
          <Switch
            onChange={onThemeChange}
            style={{ display: "flex", margin: "20px auto" }}
          />
          <Tooltip title={<ShortcursInfo />} placement="bottom">
            <Typography.Text style={{ display: "flex" }}>
              <InfoCircleOutlined
                style={{ fontSize: 20, margin: "0px auto" }}
              />
            </Typography.Text>
          </Tooltip>
        </Sider>
        <Layout>
          <Content style={{ margin: "0 16px" }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

const ShortcursInfo = (): JSX.Element => {
  return (
    <>
      <div>Tables shortcuts:</div>
      <div>
        1. <b>Click</b> on the table cell to copy the value
      </div>
      <div>
        2. <b>Double click</b> on the row to open the edit mode
      </div>
      <div>
        3. <b>Enter</b> to save the changes
      </div>
      <div>
        4. <b>Esc</b> when cursor on editing field to cancel the changes
      </div>
    </>
  )
}

const Logout = (): JSX.Element => {
  return (
    <NavLink
      to="/login"
      onClick={() => {
        localStorage.clear()
      }}
    >
      Logout
    </NavLink>
  )
}

const getMenuItem = (category: any) => {
  switch (category.category_name) {
    case "Administrative Roles":
      return {
        key: category.category_id,
        label: <NavLink to="/">Administration and roles</NavLink>,
        icon: <UserOutlined />,
      }
    case "Passwords and access":
      return {
        key: category.category_id,
        label: <NavLink to="/passwords">Passwords</NavLink>,
        icon: <FileOutlined />,
      }
    case "Networks":
      return {
        key: category.category_id,
        label: <NavLink to="/networks">Networks</NavLink>,
        icon: <TeamOutlined />,
      }
    case "Users/Clients":
      return {
        key: category.category_id,
        label: <NavLink to="/integrations">Integrations</NavLink>,
        icon: <DesktopOutlined />,
      }
  }
}
